// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

/* Exported from http://mcg.mbitson.com/#!?bioracermotion=%230098ce&themename=Bioracer%20Motion */
$md-bioracermotion: (
    50 : #e0f3f9,
    100 : #b3e0f0,
    200 : #80cce7,
    300 : #4db7dd,
    400 : #26a7d5,
    500 : #0098ce,
    600 : #0090c9,
    700 : #0085c2,
    800 : #007bbc,
    900 : #006ab0,
    A100 : #daeeff,
    A200 : #a7d7ff,
    A400 : #74bfff,
    A700 : #5ab3ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

/* Exported from http://mcg.mbitson.com/#!?bioracermotion2=%23ff5517&themename=Bioracer%20MOtion */
$md-bioracermotion2: (
    50 : #ffebe3,
    100 : #ffccb9,
    200 : #ffaa8b,
    300 : #ff885d,
    400 : #ff6f3a,
    500 : #ff5517,
    600 : #ff4e14,
    700 : #ff4411,
    800 : #ff3b0d,
    900 : #ff2a07,
    A100 : #ffffff,
    A200 : #fff5f4,
    A400 : #ffc7c1,
    A700 : #ffb0a7,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
//$my-motion-primary: mat.define-palette(mat.$cyan-palette);
//$my-motion-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$my-motion-primary: mat.define-palette($md-bioracermotion);
$my-motion-accent: mat.define-palette($md-bioracermotion2);

// The warn palette is optional (defaults to red).
$my-motion-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$my-motion-theme: mat.define-light-theme((
  color: (
    primary: $my-motion-primary,
    accent: $my-motion-accent,
    warn: $my-motion-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($my-motion-theme);
