.spacer {
    flex: 1;
  }

@media screen {
  html, body { height: 100vh; margin: 0; overflow-y: hidden; }
}

body { font-family: Roboto, "Helvetica Neue", sans-serif; }

/* The form fields are cut off at the top */
mat-form-field {
  padding-top: 5px;
}

@media print {
  .no-print, .no-print * {
    display: none !important;
  }

  @page
  {
   size: A4;
   size: portrait;
  }
}
